import Error from "next/error";

import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useInterval } from "react-use";

export default function FourOhFour() {
  const [timeLeft, setTimeLeft] = useState(5);

  const router = useRouter();

  useInterval(() => {
    if (timeLeft === 1) {
      router.push("/");
    }

    if (timeLeft > 0) {
      setTimeLeft(timeLeft - 1);
    }
  }, 1000);

  let message = "Page not found. ";

  if (timeLeft < 1) {
    message += "Redirecting to the homepage.";
  }

  if (timeLeft > 0) {
    message += `Redirecting to the home page in ${timeLeft} second`;
  }
  if (timeLeft > 1) {
    message += "s";
  }

  return (
    <>
      <Error statusCode={404} title={message} />
    </>
  );
}
